// site logo
// all (ansillary, main, sub)
// search wrapper (ansillary wrapper)
// theme main nav
// theme sub nav
// is-fixed
// has-logo
// injected elements
// @mediaqueries
#topNav {

  .has-main-nav & {
    
    border-bottom: 5px solid #083668;
    
  }

  &.topNav {
    //agnostic things
    // site logo
    background-color: white;
    #sn-site-logo {
      width: 100px;
      height: 150px;
      
      padding: 0px;
      
      
      background-color: transparent;
      
      
      border:0;
      
      transition: top 0.2s linear;
    }
    // all
    .theme-search-wrapper,
    .theme-main-nav,
    .theme-sub-nav {
      
      max-width:100%;
      
    }
    // search wrapper
    .theme-search-wrapper {
      background-color: #a4a7ab;
      
      max-width:100%;
      
      
      border: 0;
      
      z-index: 1;
      position: relative;
    }
    .theme-search-bar .searchBarText {
      padding: 0 15px;
      flex: 0 0 auto;
      white-space:pre;
    }
    .theme-search-bar .theme-search-box {
      padding-right: 15px;
    }
    .theme-search-bar  {
      
      max-width: 1200px;
      
      left: 0px;
      height:40px;
      .nav-fixed & {
        line-height: 0.01px;
        height: 0.01px;
      }
    }
    // network nav bar
    #networkNavBar {
      
    }
    // theme main nav
    .theme-main-nav {
      display: flex !important;
      align-items: center; // this might need to be pecific to layout 1
      background-color: white;
      
      max-width: 1200px;
      
      
      .theme-nav-style-dropdown > .theme-nav-item {
        border-right: 0;
      }
      
      .theme-nav {
        line-height: 40px;
        max-height: 40px;
      }
      // nav spacing not focusing on this right now
      // #topNav .theme-nav-style-dropdown > .theme-nav-item {
      //   margin: 0 20px;
      // }
      // #topNav .theme-nav-style-dropdown > .theme-nav-item:first-of-type {
      //   margin-left: 0px;
      // }
      // #topNav .theme-nav-style-dropdown > .theme-nav-item:last-of-type {
      //   margin-right: 0px;
      // }
      // typography declarations
      //nav items
      .theme-nav > .theme-nav-item > .theme-nav-link {
        font-family: "Titillium Web", sans-serif;
        font-weight: bold;
        font-size: 14px;
        color: #083668;
        text-transform: uppercase;
        background-color: transparent;
      }
      .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
        background-color: transparent;
      }
      .theme-nav > .theme-nav-item.selected > .theme-nav-link {
        background-color: transparent;
        // color: white;
      }
      .theme-nav > .theme-nav-item:hover > .theme-nav-link {
        // color: white;
      }
      // nav item hasChild indicators
      .theme-nav-style-dropdown > .theme-nav-item.hasChild > .theme-nav-link:before {
        color: #333333;
      }
      .theme-nav-style-dropdown > .theme-nav-item.dropdown-open > .theme-nav-link:before {
        color: #083668;
      }
      // dropdowns
      // dropdown container
      .theme-nav-style-dropdown ~ .theme-nav-dropdown {
        font-size: 14px;
        
        border-top: 1px solid rgba(255,255,255,0.1);
        
        
        border-bottom: 1px solid rgba(255,255,255,0.1);
        
        background-color: rgba(255,255,255,.8);
      }
      // dropdown item
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item {
        font-family: "Titillium Web", sans-serif;
        font-weight: bold;
        color: #083668;
        a { color: #083668; }
        text-transform: uppercase;
        
      }
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item.disabled > .theme-nav-link:hover,
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item > .theme-nav-link:hover {
        color: #083668;
      }
      .theme-nav-style-dropdown ~ .theme-nav-dropdown > .theme-nav-item > .theme-nav-link:not(x):hover {
        background-color: white;
      }
    }
    // theme sub nav
    .theme-sub-nav {
      font-family: "Titillium Web", sans-serif;
      font-weight: bold;
      font-size: 14px;
      background-color: #eaeaea;
      
      border: 0;
      
    }
    .theme-sub-nav .theme-nav {
      line-height: 40px;
    }
    .theme-nav-style-dropdown > .theme-nav-title a {
      color: #777777;
    }
    .theme-sub-nav .theme-nav > .theme-nav-item > .theme-nav-link {
      background-color: #dedede;
      color: #888888;
      text-transform: uppercase;
      
    }
    .theme-sub-nav .theme-nav > .theme-nav-item > .theme-nav-link:not(x):hover {
      background-color: #555555;
      color: #cccccc;
    }
    // if top-nav has logo
    //
    // if top-nav is fixed
    &.nav-fixed.has-site-logo .theme-search-bar,
    &.nav-fixed.has-site-logo .theme-nav-style-dropdown {
      left: 0;
    }
    // injected elements
    .headerCallToAction {
      width: initial;
      display:inline-block;
      text-align: center;
      margin:0 15px;
      a {
        
        border: 1px solid #083668;
        background-color: #083668;
        
        padding: 5px 20px;
        border-radius: 0px;
        font-family: "Titillium Web", sans-serif;
        font-weight: bold;
        font-size: 18px;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 0;
        white-space: nowrap;
        :hover {
          text-decoration: none;
        }
      }
    }
    .social-media-links {
      .pageElement {
        margin:0;
      }
      .sn-social-media-list .sn-social-media-icon {
        background-color: #083668;
        height: 40px;
        width: 40px;
        margin: 0 2.5px;
        float: left;
        
        border-radius: 0;
        
        &:before {
          width: 40px;
          line-height: 40px;
        }
      }
    }
    // beholden to layout 1 & 2
    &.layout-1,
    &.layout-2 {
      #sn-site-logo {
        top: 41px;
        margin: 0px;
        
        left: calc((100% - 1200px)/2);
        
        .nav-fixed & {
          top:1px;
        }
      }
      .theme-nav-style-dropdown {
        max-width: 830px;
        margin: 0;
        left: 0;
      }
      &.has-site-logo .theme-nav-style-dropdown {
        
        margin-left: calc(((100% - 1200px)/2) + 100px);
        
      }
      @media screen and (max-width:1260px) {
        .page-manager-visible &.has-site-logo .theme-nav-style-dropdown {
          margin-left: 100px;
        }
        .page-manager-visible & #sn-site-logo {
          left:0px;
        }
      }
      @media screen and (max-width:1200px) {
        &.has-site-logo .theme-nav-style-dropdown {
          margin-left: 100px;
        }
        & #sn-site-logo {
          left:0px;
        }
      }
    }
    // beholden to layout 2
    &.layout-2 {
      background-color: white;
      #sn-site-logo {
        top: calc(20px + 1px); // if it has a border bottom...
        transition: top 300ms ease, height 300ms ease;
        .nav-fixed & {
          top:0px;
          height: 40px;
        }
      }
      .theme-search-bar {
        max-width: 1100px;
        margin-left: calc(((100% - 1200px)/2) + 100px);
      }
      .theme-main-nav {
        height: 40px;
        background-color: transparent;
      }
      .theme-main-nav .theme-nav {
        background-color: white;
        padding-right: 40px;
        &:before {
          content: '';
          background: white;
          position: absolute;
          right: 90%;
          width: 100%;
          height: 40px;
          //fix for 1px gap issue
          margin-right: -1px;
        }
        &:after {
          content: '';
          position: absolute;
          height: 0px;
          width: 0px;
          left: calc(100% - 40px);
          border: 20px solid white;
          border-left-color: white;
          border-top-color: white;
        }
      }
      @media screen and (max-width:1260px) {
        .page-manager-visible & .theme-search-bar {
          margin-left: 100px;
          width: calc(100% - 100px);
        }
      }
    }
    &.layout-3 {
      #sn-site-logo {
        // margin-left: -50px;
        margin-left: 0;
        left: 50%;
        transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        top: 41px; //40px;
        //height: 98px;
        transition: height 300ms linear, width 300ms linear, top 300ms linear;
        .nav-fixed & {
          top:1px !important;
          height: 80px; // 80px default
          width: 80px; // 80px default;
          margin-left: 0;
          left: 50%;
          transform: translate(-50%);
        }
        
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 70%;
        // margin-left: -25%;
        z-index: 1;
        padding: 0;
        border:0;
        background-color: transparent;
        .nav-fixed & {
          top:1px !important;
          height: 80px;
          width: 70%;
          // margin-left: -25%;
          &:before,
          &:after {
            width:calc((100% - 80px)/2);
          }
          .sn-site-logo-wrap {
            width: 80px;
          }
        }
        &before,
        &:after,
        .sn-site-logo-wrap {
          align-self: auto;
          min-width: 0;
          min-height: auto;
        }
        &:before {
          content: 'SOUTH RIVER';
          order:1;
          flex:0 1 auto;
          justify-content: flex-end;
          display: flex;
        }
        .sn-site-logo-wrap {
          order:2;
          flex:0 1 100px;
          width: 100px;
          padding: 0px;
          border: 0px solid grey;
          margin: 0 10px;
          transition: width 300ms linear;
          
          background-color: transparent;
          
          
        }
        &:after {
          content: 'VOLLEYBALL CLUB';
          order:3;
          flex:0 1 auto;
        }
        &:before,
        &:after {
          width:calc((100% - 100px)/2);
          font-family: "Source Sans Pro", sans-serif;
          font-weight: bold;
          font-size: 40px;
          color: #083668;
          text-transform: uppercase;
          transition: width 300ms linear;
        }
        
      }
      .theme-main-nav .theme-nav {
        display: flex;
        justify-content: center;
        line-height: 40px;
        padding-top: 100px; // 150px;
        max-width: 1200px;
        left: 0;
        transition: padding-top 300ms linear;
        max-height: initial;
        .nav-fixed & {
          padding-top: 80px;
        }
      }
      .theme-main-nav .theme-nav > .theme-nav-item > .theme-nav-link {
        border-bottom: 5px solid transparent;
        margin-bottom: -1 - 5px;
        transition: border-bottom-color 300ms linear;
      }
      .theme-main-nav .theme-nav > .theme-nav-item.selected > .theme-nav-link {
        border-bottom: 5px solid #a4a7ab;
      }
      .theme-main-nav .theme-nav > .theme-nav-item:hover > .theme-nav-link {
        border-bottom: 5px solid #a4a7ab;
      }
      .theme-sub-nav .theme-nav {
        display: flex;
        justify-content: center;
        max-width: 1200px;
        left: 0;
      }
      .theme-search-wrapper {
        display: flex;
        padding: 0 calc((100% - 1200px)/2);
        height: 40px;
        transition: height 300ms linear;
        z-index: 1;
        position: relative;
      }
      .nav-fixed & .theme-search-wrapper {
        height: 0.01px;
      }
      .headerCallToAction {
        position: absolute;
        top: 50%; //calc(75px + 20px);
        right: calc((100% - 1200px + 5px)/2);
        transform: translateY(-50%);
        transition: top 300ms linear;
        
        .nav-fixed & {
          margin-top: -18px;
        }
      }
      .social-media-links {
        display: flex;
        align-items: center;
      }
      @media screen and (max-width:1260px) {
        .page-manager-visible & .headerCallToAction {
          right:calc((100% - 100%)/2);
        }
      }
      @media screen and (max-width:1200px) {
        & .headerCallToAction {
          right:calc((100% - 100%)/2);
        }
      }
      + #topNavPlaceholder {
        // height:calc((
        // 
        // 40 +
        // 
        // 
        // 45 +
        // 150
        // 
        // )*1px);
        height: 185px;
      }
      .has-sub-nav & + #topNavPlaceholder {
        // height:calc((
        // 
        // 40 +
        // 
        // 
        // 45 +
        // 150 +
        // 
        // 
        // 40
        // 
        // )*1px);
        height: 227px;
      }
    }

    &.nav-fixed + #topNavPlaceholder {
      // height: calc((  30 + 120  )*1px);
      height: 132px;
    }

    .has-sub-nav &.nav-fixed + #topNavPlaceholder { height: calc((  40 + 30 + 120  )*1px); }

    @media screen and (max-width:1024px) {
      .headerCallToAction,
      .theme-search-wrapper,
      .theme-main-nav {
        display: none !important;
      }

      #sn-site-logo {
        height: 35px;
        top: 5px !important;
        padding: 10px;
        margin: 0 50px !important;
        width: calc(100% - 100px) !important;
        left: 0 !important;
        transform: none !important;
      }

    }
  }

  .slide-nav-open & {

    &.topNav #sn-site-logo { display: none; }

  }

}
// no sub-nav
#topNavPlaceholder {
  height:calc((
  
  40 +
  
  
  45
  
  )*1px);
}
.has-sub-nav #topNavPlaceholder {
  height:calc((
  
  40 +
  
  
  45 +
  
  
  40
  
  )*1px);
}
// things to check
//   responsiveness
//   if nav is fixed
//   if nav has logo
//
@media screen and (min-width:1024px){
   #topNav.topNav.layout-3 {
     #sn-site-logo {
      height: 98px;
   }
}
}
